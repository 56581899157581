<template>
  <van-pull-refresh v-model="isLoading" pulling-text="Tarik ke bawah untuk refresh..."
    loosing-text="Lepaskan untuk refresh..." success-text="Refresh Berhasil" @refresh="onRefresh">
    <div class="loan-status">
      <BaseHeaderBar :showLogOut="false" :showBackButton="true" />

      <div class="content">
        <div class="status1" v-if="orderStatus === 1 || orderStatus === 2 || orderStatus === 10">
          <img src="@/assets/img/jisushenhe.png" alt="" />
          <div>Dalam proses peninjauan</div>
          <span>Nominal pencairan dana tergantung pada hasil akhir evaluasi</span>
        </div>
        <div v-else-if="orderStatus === 3" class="status3">
          <img src="@/assets/img/zhengzaifangkuan.png" alt="" />
          <div>Dalam proses pencairan</div>
          <div class="info">
            <div>
              <p>Jumlah yang diterima</p>
              <p class="jine">
                Rp{{ formatNumberWithCommas(loanInfo.ardrrivalAmount) }}
              </p>
            </div>
            <div>
              <p>Nama bank penerima</p>
              <p>{{ loanInfo.ardrrivalBank }}</p>
            </div>
            <div>
              <p>Nomor rekening bank penerima</p>
              <p>{{ loanInfo.ardrrivalBankcard }}</p>
            </div>
          </div>
        </div>

        <div v-else-if="orderStatus === 4" class="status4">
          <img src="@/assets/img/zanwufajieqian.png" alt="" />
          <div>Anda tidak dapat melakukan pinjaman saat ini</div>
          <span>
            Untuk saat ini Anda tidak memenuhi persyaratan. Pastikan <br />untuk
            selalu mempertahankan riwayat kredit yang baik.
          </span>
          <a href="https://apps.apple.com/id/app/tead-pinjam-tunai-mudah/id6743200553?l=id">
            <img class="daichao1" src="@/assets/img/daichao1.png">
          </a>
        </div>

        <div v-else-if="orderStatus === 5" class="status5">
          <img src="@/assets/img/state5.png" alt="" />
          <div class="daihuankuan">
            <div class="tilte">Menunggu pembayaran</div>
            <div class="huankuanriqi">
              Harap pembayaran sebelum tanggal
              {{ loanInfo.bidrllDetails.redrpaymentTime.substr(0, 2) }} bulan
              {{ loanInfo.bidrllDetails.redrpaymentTime.substr(3, 2) }}
              tahun {{ loanInfo.bidrllDetails.redrpaymentTime.substr(6) }}
            </div>
            <div class="yuqitianshu">
              Sudah terlambat {{ loanInfo.bidrllDetails.ovdrerdueDay }} hari
            </div>
            <span class="info" @click="goRepaymenDetail">Detail Pinjaman</span>
          </div>
          <span class="btn">Nominal Pelunasan:Rp{{
            formatNumberWithCommas(
              loanInfo.bidrllDetails.fudrllrepaymentAmount
            )
          }}</span>
          <span class="btn">Nominal Pembayaran Perpanjangan:Rp{{
            formatNumberWithCommas(
              loanInfo.bidrllDetails.exdrhibitionrepaymentaAount
            )
          }}</span>
          <div class="handle_btn">
            <button type="button" class="confirm-btn" @click="goRepay(1)">
              Pelunasan
            </button>
            <button type="button" class="confirm-btn" @click="goRepay(2)">
              Perpanjangan
            </button>
          </div>

        </div>

        <div v-else-if="
          orderStatus === 0 || orderStatus === 6 || orderStatus === 7
        " class="loan-status6">
          <div class="loan-status6-content">
            <img class="status0_icon" src="@/assets/img/status0_icon.png" alt="" />
            <div class="loan-title">
              <span>Nominal Pinjaman</span>
            </div>

            <div class="loan-amount-sort">
              <div ref="loanItem" v-for="(item, index) in loanInfo.mudrltipleLoanList" :key="index"
                :class="{ active: activeIndex === index }" @click="selectLoan(item, index)">
                Rp{{ formatNumberWithCommas(item.loanMoney) }}
                <img v-if="item.loanStatus" :src="require(`@/assets/img/can-loan-icon${activeIndex === index ? '-active' : ''
                  }.png`)
                  " />
              </div>
            </div>
            <div class="info-block">
              <div v-if="loanTimers && loanTimers.length">
                <template v-if="hasRate">
                  <div class="row">
                    <div>
                      <span>Tenor Pinjaman</span>
                    </div>
                    <span>{{ loanInfo.bidrgDeadate }} Hari</span>
                  </div>
                </template>
                <template v-else>
                  <div class="jiekuanqixian">
                    <div>
                      <span>Tenor Pinjaman</span>
                    </div>
                    <span>{{ loanTimers[0] }}Hari</span>
                  </div>
                </template>
              </div>
              <div class="row" v-if="hasRate">
                <div>
                  <span>Suku bunga tahunan</span>
                </div>
                <span>{{ loanInfo.dadryRate }}</span>
              </div>
            </div>
          </div>
          <button type="button" class="submit" @click="submit">
            Segera Pengajuan
          </button>
          <Recommendation :orderStatus="orderStatus"></Recommendation>
          <van-dialog v-model="visible" :showConfirmButton="false" close-on-click-overlay class="no-loan-dialog">
            <div class="dialog_body">
              <span class="dialog_title">Tingkatkan Nominal</span>
              <h3 class="tip-title">Bagaimana cara menaikkan limit pinjaman</h3>
              <p class="tip">
                Mempertahankan kebiasaan baik dan selalu melunasi tepat waktu.
                Anda bisa mendapatkan kenaikan limit apabila menghindari
                perilaku keterlambatan pembayaran.
              </p>
              <button type="button" class="confirm-btn" @click="onCloseDialog()">
                Mengerti
              </button>
            </div>
          </van-dialog>
        </div>

        <div v-else-if="orderStatus === 8" class="status8">
          <img src="@/assets/img/renzhengshibai.png" alt="" />

          <div>Verifikasi KTP gagal</div>
          <span>
            Foto KTP yang Anda unggah tidak jelas, sehingga tidak dapat lolos
            evaluasi. Silakan unggah ulang.
          </span>
          <button type="button" class="modify-btn" @click="goAuthentication">
            Edit data
          </button>
        </div>

        <div v-else-if="orderStatus === 9" class="status9">
          <img src="@/assets/img/fangkuanshibai.png" alt="" />
          <div>Pencairan gagal</div>
          <span>
            Pencairan tidak dapat dilakukan karena data rekening <br />
            bank Anda tidak tersedia. Silakan isi ulang.
          </span>
          <button type="button" class="modify-btn" @click="goBankInfo">
            Edit data
          </button>
          <van-dialog v-model="cancelLoanVisible" :showConfirmButton="false" close-on-click-overlay>
            <p class="cancel-loan-dialog-title">Berhasil dibatalkan</p>
            <p class="tip">
              Pinjaman Anda telah dibatalkan. Anda tidak perlu verifikasi data
              lagi jika ingin melakukan pinjaman.
            </p>
            <button type="button" class="confirm-btn" @click="onCloseDialog('cancelLoanVisible')">
              Mengerti
            </button>
          </van-dialog>
        </div>

        <div v-else-if="orderStatus === 12" class="loan-status12">
          <img src="@/assets/img/state12.png" alt="" />
          <div class="jiekuanjine">
            <div>
              <span>Jumlah pinjaman</span>
            </div>
            <span> Rp{{ formatNumberWithCommas(loanInfo.lodranAmount) }}</span>
          </div>

          <div class="jiekuanqixian">
            <div>
              <span>Lama Pinjaman</span>
            </div>
            <span>{{ loanInfo.avdrailablePeriod[0] }} Hari</span>
          </div>

          <button type="button" class="withdraw-btn" @click="withdraw">
            Segera Tarik
          </button>
        </div>
        <van-dialog v-model="feeVisible" :showConfirmButton="false" close-on-click-overlay>
          <div class="fee-detail">
            <p class="fee-title">Rincian Biaya</p>
            <div>
              <p>Nominal Pinjaman</p>
              <p>Rp{{ formatNumberWithCommas(feeDetail.lodranCash) }}</p>
            </div>
            <div>
              <p>Jumlah hari pinjaman</p>
              <p>{{ feeDetail.lodranTimes }} Hari</p>
            </div>
            <div>
              <p>Pengurangan Biay</p>
              <p>Rp{{ formatNumberWithCommas(feeDetail.todrtalCost) }}</p>
            </div>
            <div>
              <p>Jumlah yang diterima</p>
              <p>Rp{{ formatNumberWithCommas(feeDetail.ardrrivalCash) }}</p>
            </div>
            <div class="footer-btn">
              <button type="button" class="cancel-btn" @click="onCloseDialog('feeVisible')">
                Batal
              </button>
              <button type="button" class="ok-btn" @click="onOkFee">
                Konfirmasi
              </button>
            </div>
          </div>
        </van-dialog>
      </div>
    </div>
    <template #loading>
      <span></span>
    </template>
  </van-pull-refresh>
</template>

<script>
import Vue from 'vue'
import { Dialog, Toast, PullRefresh, Overlay } from 'vant'
import request from '@/utils/request'
import dialogVisible from '@/mixins/dialogVisible'
import { getMetadata } from '@/utils/getEquipmentInfo.js'
import Recommendation from '@/components/Recommendation.vue'

Vue.use(Toast)
Vue.use(PullRefresh)
Vue.use(Overlay)

export default {
  name: 'Home',
  mixins: [dialogVisible],
  components: {
    [Dialog.Component.name]: Dialog.Component,
    Recommendation
  },
  data () {
    return {
      isLoading: false,
      cancelLoanVisible: false,
      feeVisible: false,
      orderStatus: '',
      loanInfo: {},
      feeDetail: {},
      activeIndex: -1,
      loanTimers: [],
      loanAmount: '',
      hasRate: false
    }
  },
  created () {
    this.getOrderStatus()
  },
  methods: {
    getOrderStatus (isRefresh) {
      request.post('homeDRInterface')
        .then(res => {
          this.orderStatus = res.ordrderStatus
          this.loanInfo = res
          this.hasRate = res.ordrderStatus === 0 || (res.ordrderStatus === 7 && res.redr_loan === 0)
          // 去好评弹窗
          if (res.ordrderStatus === 5) {
            const showEvaluate = localStorage.getItem('showEvaluate')
            if (!showEvaluate) {
              try {
                if (window.webkit.messageHandlers.hkjhfss) {
                  window.webkit.messageHandlers.hkjhfss.postMessage('')
                }
                if (window.webkit.messageHandlers.jcfuidh) {
                  window.webkit.messageHandlers.jcfuidh.postMessage('')
                }
                if (window.webkit.messageHandlers.jdjsa) {
                  window.webkit.messageHandlers.jdjsa.postMessage('')
                }
                if (window.webkit.messageHandlers.wegfdf) {
                  window.webkit.messageHandlers.wegfdf.postMessage('')
                }
              } catch (error) {
              }
            }
            localStorage.setItem('showEvaluate', true)
          }
          if (res.ordrderStatus === 12) {
            this.loanAmount = String(res.lodranMaxAmount)
          }
          if (res.ordrderStatus === 0 || res.ordrderStatus === 6 || res.ordrderStatus === 7) {
            this.loanAmount = String(res.lodranMaxAmount)
            let index = -1
            // 过滤不能借款的金额
            // if (res.ordrderStatus === 6 || (res.ordrderStatus === 7 && res.redr_loan === 1)) {
            //   this.loanInfo.mudrltipleLoanList = res.mudrltipleLoanList.filter((item) => {
            //     return item.loanStatus
            //   })
            // }
            res.mudrltipleLoanList.forEach((item, i) => {
              if (item.loanStatus) {
                index = i
              }
            })
            if (index > -1) {
              this.activeIndex = index
              this.selectedLoanItem = res.mudrltipleLoanList[index]
              this.loanAmount = String(this.selectedLoanItem.loanMoney)
              this.loanTimers = this.initLoanTimers = this.selectedLoanItem.loanTimers || []
            }
          }
          isRefresh && (this.isLoading = false)
        })
    },
    getFeeDetail (item, loanType) {
      const postData = {
        lodranCash: item.loanMoney,
        lodranTimers: item.loanTimers[0],
        lodranType: loanType
      }
      this.withdrawType = loanType
      request.post('indrterestrateBomb', postData)
        .then(res => {
          this.feeDetail = res
        })
    },
    goRepaymenDetail () {
      this.$router.push('/repaymenDetail')
    },
    goBankInfo () {
      if (this.loanInfo.badrnkStatus) {
        const postData = {
          apdrplicationAmount: this.loanInfo.lodranAmount,
          prdromotionChannel: 'AppStore',
          portraitAdd: 1
        }
        // 获取设备信息
        getMetadata()
          .then(res => {
            // 提交设备信息
            return request.post('deviceInformation', res)
          })
          .then(() => {
            // 提交订单
            return request.post('sudrbmitloanOrder', postData)
          })
          .then(() => {
            this.getOrderStatus()
          })
      } else {
        this.goAuthentication()
      }
    },
    goRepay (repayType) {
      const { ordrderID, padrymentID, fudrllrepaymentAmount, exdrhibitionrepaymentaAount } = this.loanInfo.bidrllDetails
      this.$router.push({
        path: '/repay',
        query: {
          repayType,
          amount: repayType === 1 ? fudrllrepaymentAmount : exdrhibitionrepaymentaAount,
          orderId: ordrderID,
          payID: padrymentID
        }
      })
    },
    selectLoan (item, index) {
      const loanStatus = item.loanStatus
      if (loanStatus) {
        if (index !== this.activeIndex) {
          this.activeIndex = index
          this.selectedLoanItem = item
          this.loanAmount = String(item.loanMoney)
          this.loanTimers = item.loanTimers
        }
      } else {
        this.visible = true
      }
    },
    submit () {
      if (this.selectedLoanItem) {
        if (this.orderStatus === 0 || (this.orderStatus === 7 && this.loanInfo.redr_loan === 0)) {
          this.$router.push({
            path: '/authentication',
            query: {
              amount: this.selectedLoanItem.loanMoney
            }
          })
        } else if (this.orderStatus === 6 || (this.orderStatus === 7 && this.loanInfo.redr_loan === 1)) {
          this.getFeeDetail(this.selectedLoanItem, 2)
          this.feeVisible = true
        }
      } else {
        Toast('Silahkan pilih pinjaman terlebih dahulu')
      }
    },
    onOkFee () {
      if (this.flag) return
      this.flag = true
      const { lodranCash, lodranTimes } = this.feeDetail
      const postData = {
        lodranAmount: lodranCash,
        lodranPeriod: lodranTimes,
        widrthdrawType: this.withdrawType
      }
      request.post('widrthdrawCash', postData)
        .then(() => {
          this.feeVisible = false
          this.orderStatus = 1
        })
        .finally(() => {
          this.flag = false
        })
    },
    goAuthentication () {
      this.$router.push({
        path: '/authentication',
        query: {
          amount: this.loanInfo.lodranAmount
        }
      })
    },
    withdraw () {
      const postData = {
        loanMoney: this.loanInfo.lodranAmount,
        loanTimers: this.loanInfo.avdrailablePeriod
      }
      this.getFeeDetail(postData, 1)
      this.feeVisible = true
    },
    onRefresh () {
      this.getOrderStatus(true)
    },
    // 跳转应用商店
    toStore (isToStore = true) {
      if (isToStore) {
        // window.location = ''
      }
      this.visible = false
      localStorage.setItem('showEvaluate', true)
    }
  }
}
</script>

<style scoped lang="less">
.intro {
  padding-top: 437px;
  line-height: 42px;
  font-size: 30px;
  color: #302c48;
  text-align: center;
}

.content {
  position: relative;
  z-index: 2;
  border-radius: 30px 30px 0px 0px;
  padding-top: 40px;
}

.loan-title {
  text-align: center;
  font-size: 40px;
  font-weight: bold;
  color: #fff;
  padding-top: 30px;
  margin: 0 30px;

  span {
    border-bottom: 8px solid #f35468;
    padding-bottom: 8px;
  }
}

.loan-amount {
  display: flex;
  justify-content: center;
  margin-top: 23px;

  &>div {
    width: 50px;
    height: 71px;
    line-height: 71px;
    border-radius: 6px;
    font-size: 42px;
    color: #fd411c;
    text-align: center;
    background-color: #fff;

    &:nth-child(n + 2) {
      margin-left: 12px;
    }
  }
}

.time {
  .circle {
    display: flex;
    justify-content: center;

    &>div {
      width: 48px;
      height: 48px;
      border: 1px solid #dadada;
      border-radius: 50%;
      margin-top: 22px;

      &:nth-child(n + 2) {
        margin-left: 19px;
      }

      &.active {
        width: 67px;
        height: 67px;
        line-height: 67px;
        border: 2px solid #fd411c;
        margin-top: 12px;
        font-size: 32px;
        color: #fd411c;
        text-align: center;
      }
    }
  }

  .unit {
    line-height: 33px;
    margin-top: 9px;
    font-size: 24px;
    color: #fd411c;
    text-align: center;
  }
}

.loan-amount-sort {
  display: grid;
  justify-content: space-evenly;
  grid-template-columns: repeat(auto-fill, 200px);
  overflow-x: scroll;
  margin: 0 30px;
  padding-top: 55px;
  margin-bottom: 40px;

  div {
    position: relative;
    width: 200px;
    height: 100px;
    margin-bottom: 34px;
    background: #ffffff;
    border: 2px solid #ececec;
    border-radius: 10px;
    font-size: 30px;
    font-weight: 500;
    color: #666666;
    text-align: center;
    line-height: 100px;

    &.active {
      background: #ffe2e6;
      border: 2px solid #f35468;
      color: #f35468;
      font-weight: bold;
    }

    img {
      position: absolute;
      right: 5px;
      bottom: 5px;
      width: 29px;
      height: 29px;
    }
  }
}

.loan-status {
  display: flex;
  flex-direction: column;
}

.loan-status6-content {
  .status0_icon {
    width: 176px;
    height: 176px;
    display: block;
    margin: 0 auto;
    margin-bottom: 40px;
  }
}

.status1,
.status3,
.status4,
.status8,
.status9 {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0 40px;

  img {
    margin-top: 150px;
    margin-bottom: 80px;
    width: 271px;
    height: 281px;
    text-align: center;
  }

  div {
    font-size: 34px;
    font-weight: bold;
    color: #ffffff;
    text-align: center;
    margin-bottom: 34px;
  }

  span {
    display: block;
    font-size: 30px;
    font-weight: 500;
    color: #ffffff;
    text-align: center;
  }

  .modify-btn {
    .submit-btn;
    width: 670px;
    margin-top: 101px;
  }

  .info {
    margin-top: 70px;
    width: 100%;

    div {
      height: 104px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 25px;
      font-size: 30px;
      font-weight: bold;
      color: #ffffff;
      border-bottom: 2px solid rgba(225, 225, 225, 0.2);
      margin-bottom: 0;
    }
  }
}

.status4 {
  .daichao1 {
    width: 100%;
    height: 360px;
  }
}

.status3 {
  img {
    margin-top: 20px;
  }

  div {
    text-align: left;
  }
}

.status5 {
  margin: 0 40px;

  img {
    width: 164px;
    height: 173px;
    margin: 50px auto;
  }

  .daihuankuan {
    height: 231px;
    position: relative;
    padding-left: 44px;
    padding-right: 30px;
    background: url(../assets/img/huankuan_info_bg.png) no-repeat;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin-bottom: 50px;

    div {
      color: #fff;
    }

    .tilte {
      font-size: 32px;
      font-weight: 500;
    }

    .huankuanriqi {
      font-size: 27px;
      font-weight: normal;
    }

    .yuqitianshu {
      font-size: 22px;
      font-weight: 500;
    }

    .info {
      min-width: 126px;
      height: 60px;
      background: #27b8d7;
      border-radius: 30px;
      font-size: 26px;
      font-weight: 500;
      color: #ffffff !important;
      position: absolute;
      right: 20px;
      top: 20px;
      text-align: center;
      line-height: 60px;
      padding: 0 15px;
    }
  }

  .btn {
    display: block;
    font-size: 32px;
    font-weight: bold;
    color: #fff;
    text-align: center;
    margin-bottom: 30px;
    border: 1px solid #27b8d7;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    background: #90daea;
  }

  .handle_btn {
    display: flex;
    gap: 20px;
    margin-top: 130px;
    margin-bottom: 40px;

    .confirm-btn {
      .submit-btn;
      font-size: 32px;
    }

    .confirm-btn:last-child {
      color: #ffffff;
      background: #1c8aa1;
    }
  }

  .tip {
    padding-left: 49px;
    padding-right: 49px;
    margin-top: 16px;
    font-size: 28px;
    color: #fff;
    text-align: center;
    line-height: 38px;
  }

  .toStore-btn {
    .submit-btn;
    width: 500px;
    height: 88px;
    line-height: 88px;
    border-radius: 8px;
    margin: 50px auto 50px;
    font-size: 30px;
    color: #fff;
  }

  .hiddenIcon {
    position: absolute;
    top: -30px;
    right: 20px;
    width: 50px;
    height: 50px;
  }
}

.loan-status6 {
  position: relative;
  padding-bottom: 200px;

  .page {
    display: flex;
    justify-content: center;
    margin-top: 22px;

    &>div {
      width: 28px;
      height: 14px;
      border-radius: 5px;
      border: 1px solid #ff3548;
      background-color: #fff;

      &:nth-child(n + 2) {
        margin-left: 16px;
      }

      &.active {
        width: 55px;
        background-color: #f71f42;
      }
    }
  }

  .info-block {
    padding: 0 30px;

    .row {
      height: 102px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 40px;
      border-bottom: 2px solid rgba(225, 225, 225, 0.2);
      font-size: 30px;
      font-weight: bold;
      color: #333;

      div {
        display: flex;
        align-items: center;

        span {}
      }

      span {}
    }

    .jiekuanqixian {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 30px;
      height: 94px;
      background: #fff4e9;
      border-radius: 47px;
      margin-top: 30px;

      div {
        display: flex;
        align-items: center;

        img {
          margin-right: 10px;
          width: 43px;
          height: 43px;
        }

        span {
          font-size: 30px;
          font-weight: 500;
          color: #333333;
        }
      }

      span {
        font-size: 28px;
        font-weight: bold;
        color: #333333;
      }
    }
  }

  .submit {
    .submit-btn;
    width: 670px;
    position: fixed;
    left: 50%;
    bottom: 60px;
    transform: translateX(-50%);
  }

  .no-loan-dialog {
    width: 672px;
    margin-left: auto;
    margin-right: auto;

    .dialog_body {
      padding: 0 20px;
    }

    .dialog_title {
      display: block;
      margin-top: 80px;
      margin-bottom: 100px;
      text-align: center;
      font-size: 40px;
      font-weight: 800;
      color: #ffffff;
    }
  }

  .tip-title {
    margin-bottom: 46px;
    font-size: 30px;
    font-weight: bold;
    color: #fff;
    text-align: center;
  }

  .tip {
    font-size: 26px;
    font-weight: 500;
    color: #fff;
    line-height: 34px;
  }

  .confirm-btn {
    .submit-btn;
    width: 100%;
    margin-top: 59px;
    margin-bottom: 99px;
  }
}

.loan-status12 {
  padding: 0 40px;
  padding-top: 70px;

  img {
    width: 240px;
    height: 240px;
    margin: 160px auto;
    margin-bottom: 50px;
  }

  .jiekuanjine,
  .jiekuanqixian {
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 40px;
    font-size: 30px;
    font-weight: bold;
    color: #fff;
    border-bottom: 2px solid rgba(255, 255, 255, 0.2);

    div {
      display: flex;
      align-items: center;

      span {}
    }

    span {}
  }

  .withdraw-btn {
    .submit-btn;
    width: 670px;
    margin-top: 100px;
    margin-bottom: 98px;
  }
}

.fee-title {
  text-align: center;
  font-size: 50px;
  font-weight: 800;
  color: #ffffff;
  margin-bottom: 77px;
}

.fee-detail {
  width: 672px;
  margin: 0 auto;
  margin-top: 89px;

  &>div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 61px;
    padding-left: 24px;
    padding-right: 24px;
    color: #fff;

    &:nth-child(n + 2) {
      margin-top: 6px;
    }

    p {
      font-size: 30px;
      font-weight: 500;
    }

    p:nth-child(2) {
      font-weight: bold;
    }
  }
}

.footer-btn {
  display: flex;
  gap: 20px;

  button {
    flex: 1;
  }

  .cancel-btn {
    .submit-btn;
    margin-top: 30px;
    background: #2bb9d7;
  }

  .ok-btn {
    .submit-btn;
    margin-top: 30px;
  }
}
</style>
